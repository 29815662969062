
import {HeaderComponent} from "./component/HeaderComponent";
import {MainComponent} from "./component/MainComponent";
function App() {
  return (
      <div style={{padding:'20px',maxWidth:'900px',margin:'auto'}}>
          <HeaderComponent/>
          <MainComponent/>
      </div>
  );
}

export default App;
