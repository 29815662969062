import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';

const ScratchCard = forwardRef(({
                                    width = 300,
                                    height = 150,
                                    brushSize = 35,
                                    finishPercent = 95,
                                    coverColor = '#666666',
                                    children,
                                }, ref) => {
    const canvasRef = useRef(null);
    const [isScratching, setIsScratching] = useState(false);
    const lastPoint = useRef(null);
    const animationFrame = useRef(null);
// 获取上下文时都要添加选项
    const getContext = () => {
        return canvasRef.current?.getContext('2d', { willReadFrequently: true,alpha:false});
    };
    // 初始化画布，使用 useCallback 以消除 ESLint 警告
    const initCanvas = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.fillStyle = coverColor;
        ctx.fillRect(0, 0, width, height);
    }, [width, height, coverColor]);

    // 获取刮开比例
    const getScratchedPercent = () => {
        const canvas = canvasRef.current;
        if (!canvas) return 0;
        const ctx = getContext();
        if (!ctx) return 0;
        const imageData = ctx.getImageData(0, 0, width, height).data;
        let transparentPixels = 0;
        // 优化计算性能，间隔采样
        for (let i = 3; i < imageData.length; i += 16) {
            if (imageData[i] === 0) transparentPixels++;
        }
        return (transparentPixels / ((width * height) / 4)) * 100;
    };

    // 绘制刮痕
    const drawScratch = (x, y) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = getContext();
        if (!ctx) return;

        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.arc(x, y, brushSize, 0, Math.PI * 2);
        ctx.fill();

        // 快速移动时绘制连接线，防止出现断裂
        if (lastPoint.current) {
            ctx.beginPath();
            ctx.moveTo(lastPoint.current.x, lastPoint.current.y);
            ctx.lineTo(x, y);
            ctx.lineWidth = brushSize * 2;
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.stroke();
        }
        lastPoint.current = { x, y };
    };

    // 处理刮卡操作
    const handleScratch = (clientX, clientY) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const rect = canvas.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        animationFrame.current = requestAnimationFrame(() => {
            drawScratch(x, y);
            checkCompletion();
        });
    };

    // 检查是否完成刮卡
    const checkCompletion = () => {
        const percent = getScratchedPercent();
        if (percent >= finishPercent) {
            clearRemainingCover();
        }
    };

    // 清除剩余涂层
    const clearRemainingCover = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.clearRect(0, 0, width, height);
        cancelAnimationFrame(animationFrame.current);
    };

    // 事件处理：开始、结束、移动
    const handleStart = () => {
        setIsScratching(true);  // 只有在鼠标按下时才开始刮卡
        lastPoint.current = null;
    };

    const handleEnd = () => {
        setIsScratching(false);
        lastPoint.current = null;
        cancelAnimationFrame(animationFrame.current);
    };

    const handleMove = (e) => {
        if (!isScratching) return; // 只有鼠标按下状态下才处理移动事件
        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;
        handleScratch(clientX, clientY);
    };

    // 重置方法，外部可以调用此方法重置画布
    const reset = () => {
        initCanvas();
    };

    // 初始化画布，监听宽高和背景色变化
    useEffect(() => {
        initCanvas();
        return () => cancelAnimationFrame(animationFrame.current);
    }, [initCanvas]);

    // 暴露 reset 方法给父组件
    useImperativeHandle(ref, () => ({
        reset,
    }));

    return (
        <div className="scratch-container">
            <div
                className="card-wrapper"
                style={{
                    position: "relative",
                    width: `${width}px`,
                    height: `${height}px`,
                    background: "white",
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                }}
            >
                {/* 显示刮开后的内容 */}
                {children}
                <canvas
                    ref={canvasRef}
                    className="scratch-layer"
                    onMouseDown={handleStart}
                    onMouseUp={handleEnd}
                    onMouseMove={handleMove}
                    onMouseLeave={handleEnd}
                    onTouchStart={handleStart}
                    onTouchEnd={handleEnd}
                    onTouchMove={handleMove}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        touchAction: "none",
                        cursor: "crosshair",
                        background: "transparent",
                    }}
                />
            </div>
        </div>
    );
});

export default ScratchCard;
