import {Tabs,Grid,Button} from "@arco-design/web-react";
import {useState} from "react";
import {PC28Component} from "./PC28Component";
import {SixMarkComponent} from "./SixMarkComponent";
import {VideoBaccaratComponent} from "./VideoBaccaratComponent";
// const tabTitle =['PC28','PC牛牛','六合彩','视讯百家乐','视讯牛牛','极速骰子']
const tabTitle =['PC28','PC牛牛','六合彩']
const { Row, Col } = Grid;

export const MainComponent = () => {
    const [selectIndex, setSelectIndex] = useState(0)
    {/*return  <div style={{ marginTop: '20px' }}>*/}
    {/*    /!* 使用 Grid 布局展示 Tab 标题 *!/*/}
    {/*    <Row gutter={[0, 0]}>*/}
    {/*        {tabTitle.map((item, index) => (*/}
    {/*            <Col key={index} span={12}> /!* 每行展示两个 Tab，每个占 12 列 *!/*/}
    {/*                <Button*/}
    {/*                    type={selectIndex === index ? 'primary' : 'default'}*/}
    {/*                    size="large"*/}
    {/*                    style={{*/}
    {/*                        width: '100%',*/}
    {/*                        fontSize: '18px',*/}
    {/*                        fontWeight: 'bold',*/}
    {/*                        borderRadius: '0px', // 圆角效果*/}
    {/*                    }}*/}
    {/*                    onClick={() => setSelectIndex(index)}*/}
    {/*                >*/}
    {/*                    {item}*/}
    {/*                </Button>*/}
    {/*            </Col>*/}
    {/*        ))}*/}
    {/*    </Row>*/}

    {/*    /!* 根据 selectIndex 显示对应的内容 *!/*/}
    {/*    <div style={{ marginTop: '20px' }}>*/}
    {/*        {selectIndex === 0 && <PC28Component />}*/}
    {/*        {selectIndex === 1 && <PC28Component type={1} />}*/}
    {/*        {selectIndex === 2 && <SixMarkComponent />}*/}
    {/*        {selectIndex === 3 && <VideoBaccaratComponent />}*/}
    {/*    </div>*/}
    {/*</div>*/}
    return (
        <>
            <br/>
            <br/>
            <Tabs activeTab={String(selectIndex)}
                  size={'large'}
                  type={"rounded"}
                  onChange={key =>{
                      setSelectIndex(Number.parseInt(key))
                  }}
            >
                {tabTitle.map((item, index) => {
                    return <Tabs.TabPane
                        key={String(index)}
                        title={<span style={{ fontSize: "18px", fontWeight: "bold" }}>{item}</span>}
                      >
                        {selectIndex === index && index === 0 && <PC28Component />}
                        {selectIndex === index && index === 1 && <PC28Component type={1} />}
                        {selectIndex === index && index === 2 && <SixMarkComponent />}
                        {selectIndex === index && index === 3 && <VideoBaccaratComponent />}
                    </Tabs.TabPane>
                })}
            </Tabs>
        </>
    )
}