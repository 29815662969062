import {Image, Space, Tag} from "@arco-design/web-react";
import  { ReactComponent as DarkIcon } from '../img/dark.svg';
import { ReactComponent as LightIcon } from '../img/light.svg';
import header_ad from "../img/header_ad.jpg";
import kk_logo from "../img/kk_logo.png";
import {useContext, useState} from "react";
import {AppThemeContext} from "../AppThemeContext";

const headerTagStyle = {
    backgroundColor: '#fff',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',    // 图标和文字垂直居中
    justifyContent: 'center',
    cursor: 'pointer',       // 鼠标悬停时显示手型光标
    borderRadius: '4px',
    transition: 'background-color 0.3s',
}

export const HeaderComponent = () => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const  isTheme = false;
    // 用于标记当前 hover 的 tag 索引
    const themeContext = useContext(AppThemeContext); // 先检查是否拿到值


    if (!themeContext) {
        console.log("ThemeContext:", themeContext); // 打印调试，看看是否为 null 或 undefined
        return <div>主题加载失败</div>; // 避免代码崩溃
    }
    const { theme, setTheme } = themeContext; // 这里确保不会出错

    return (
        <>
             <div style={{display:"flex",flexDirection:'row', justifyContent:'space-between', alignItems: 'center',marginBottom:'1%'}} >
                <Image
                    src={kk_logo}
                    alt='kk_logo'
                    width={200}
                    preview={false}
                />
                 {isTheme &&  <Space size='mini' margin='10px'>
                     <Tag
                         style={{
                             ...headerTagStyle,
                             backgroundColor: hoverIndex === 0 ? '#f0f0f0' : '#fff',
                             color:theme === 'light'?'blue':'black',
                         }}
                         icon={<LightIcon  alt="浅色模式" style={{width: 20, height: 20, marginTop: 5}} />}
                         // icon={<img src={light} alt="深色模式" style={{width: 20, height: 20, marginTop: 5,color:selectIndex === 0?'lightblue':null}} />}
                         onMouseEnter={() => setHoverIndex(0)}
                         onMouseLeave={() => setHoverIndex(null)}
                         onClick={() =>  setTheme('light')}
                     >
                         浅色模式
                     </Tag>
                     <Tag style={{
                         ...headerTagStyle,
                         backgroundColor: hoverIndex === 1 ? '#f0f0f0' : '#fff',
                         color:theme === 'dark'?'blue':'black'
                     }}
                          icon={<DarkIcon  alt="深色模式" style={{width: 18, height: 18, marginTop: 5}} />}
                         // icon={<img src={dark} alt="深色模式" style={{width: 18, height: 18,marginTop: 5,color:selectIndex ===1?'lightblue':null}}/>}
                          onMouseEnter={() => setHoverIndex(1)}
                          onMouseLeave={() => setHoverIndex(null)}
                          onClick={() =>  setTheme('dark')}
                     >
                         深色模式
                     </Tag>
                 </Space>}
            </div>
            <Image
                src={header_ad}
                alt='header_ad'
                preview={false}
                onClick={()=>{
                    window.open('https://t.me/pc228', '_blank');
                }}
            />

        </>
    )
}