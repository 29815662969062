import React, {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";

import {Avatar, Table, Tabs} from "@arco-design/web-react";
import {CountdownTimer} from "./CountdownTimer";
import {getTodayTimestamps} from "../utils/Utils";

export const VideoBaccaratComponent = ({type = 0}) => {
    // 记录最新期号，由 SixMarkLastComponent 更新
    const [lastTerm, setLastTerm] = useState(null);
   return  <>
        <BaccaratVideoLastComponent  type={type}/>
        <BaccaratVideoHistoryComponent  lastTerm={lastTerm} type={type} />
    </>
}
const BaccaratVideoLastComponent = ({type = 0}) => {
    const [currentData, setCurrentData] = useState(null);
    // 获取数据的方法，使用 async/await
    const lastFetchData = useCallback(async () => {
        try {
            const requestUrl = type? 'https://t3.kkaa.vip':'https://t2.kkaa.vip';
            const response = await axios.get(`${requestUrl}/api/status`);
            const {resultCode,data} = response.data;
            if(!resultCode){
                setCurrentData(null);
                return false;
            }
            setCurrentData(data);
            return true;
        } catch (error) {
            console.error('数据加载失败:', error);
        }
        return false; // 数据未更新
    }, [type]);

    // 组件挂载时请求数据
    useEffect(() => {
        lastFetchData();
    }, [lastFetchData]);
    if(currentData === null){
        return null;
    }
    const term = `第${currentData.an}靴${currentData.term}期`
    return<>
        <CountdownTimer
            isTimestamp={false}
            initialTime={Number(currentData.seconds)}
            // second={Number(currentData.seconds)}
            status={currentData.status}
            term ={term}
            onRefresh={lastFetchData} />
    </>

}
 const BaccaratVideoHistoryComponent = ({lastTerm ,type}) => {
    const [dataList, setDataList] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [timestamps, setTimestamps] = useState({ startTimestamp: -1, endTimestamp: -1 });

    useEffect(() => {
        const { startTimestamp, endTimestamp } = getTodayTimestamps();
        setTimestamps({ startTimestamp, endTimestamp });
    }, []);

    // 获取数据的方法
    const historyData = useCallback(async (type) => {
        // setLoading(true);
        try {
            const  baseUrl = type === 0 ? 'https://t3.kkaa.vip':'https://t2.kkaa.vip'
            const response = await axios.post(`${baseUrl}/admin/lottery/record`,
                {
                    'page':1,
                    'pageSize':100,
                    'salesman':false,
                    'startTime':timestamps.startTimestamp,
                    'endTime':timestamps.endTimestamp,
                },
                {headers: {
                    'Content-Type': 'application/json',
                    'LANGUAGE': 'SIMPLIFIED_CHINESE',
                    'CLIENT-VERSION':'1.0.0',
                    'DEVICE-TYPE':'Web-React'
                }}
            );
            const { errorCode, result } = response.data;
            if (errorCode !== 0) {
                console.error('数据加载失败:', errorCode);
                return;
            }

            const { bodyList } = result.data;
            if (!Array.isArray(bodyList)) {
                console.error('数据加载失败结果不是集合:', bodyList);
                return;
            }
            if (bodyList.length > 0) {
                setDataList(bodyList);
                return true; // 数据已更新
            } else {
                setDataList([]);
            }
        } catch (error) {
            console.error('数据加载失败:', error);
        } finally {
            // setLoading(false);
        }
        return false; // 数据未更新
    },[timestamps.startTimestamp,timestamps.endTimestamp]);

    // 监听 type,lastTerm 变化调用 historyData
    useEffect(() => {
        historyData(type);
    }, [type,lastTerm,historyData]);


    const columns = [
        {
            title:'期号',
            dataIndex: 'term',
            key: 'term',
            align: 'center',
            render: (col, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span> { record.term}期</span>
                </div>
            ),
        },
        {
            title: '庄家牌',
            dataIndex: 'banker',
            key: 'banker',
            align: 'center',
        },
        {
            title: '闲家牌',
            dataIndex: 'player',
            key: 'player',
            align: 'center',
        },
        {
            title: '结果',
            dataIndex: 'result',
            key: 'result',
            align: 'center',
        },
    ];

    return (
        <>
            <Table
                border={{
                    wrapper: true,
                    cell: true,
                    fixed: true,
                }}
                columns={columns}
                data={dataList}
                pagination={false}
                // loading={loading}
                rowKey={(record, index) => record.shoe - record.term} // 使用 index 作为 key
            />
        </>
    );
};

