import {createContext, useEffect, useState} from "react";
import { ConfigProvider } from "@arco-design/web-react";
import '@arco-design/theme-line/css/arco.css';

///创建主题Context
export const AppThemeContext = createContext(null);

export  const AppThemeProvider = ({children}) => {

    const savedTheme = localStorage.getItem('theme') || 'light';
    const [theme, setTheme] = useState(savedTheme)
    useEffect(() => {
        localStorage.setItem('theme', theme);
        if (theme === "dark") {
            document.body.setAttribute("arco-theme", "dark");
        } else {
            document.body.removeAttribute("arco-theme");
        }
    },[theme])
    return (
        <AppThemeContext.Provider  value={{theme,setTheme}}>
            <ConfigProvider theme={theme}>
                {children}
            </ConfigProvider>
        </AppThemeContext.Provider>
    );
}
