import {Button, Modal} from "@arco-design/web-react";
import ScratchCard from "./widget/ScratchCard";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {convertMillisecondsToHMS, twoDist} from "../utils/Utils";
import scratcherBg from '../img/scratcher_bg.png';
import {isMobile} from "react-device-detect";


const timerStyle = {
    fontWeight: "bold",
    color: "red",
    fontSize: '20px',
    backgroundColor: 'white',
    borderRadius: '4px',
    padding: isMobile?'5px':'10px',
}

export const ScratchCardsDialog = ({lastData, isOpen, onClose}) => {
    const scratchRef = useRef(null);
    // 如果 lastData 不存在，提供一个默认值，避免 TypeError
    const term = lastData?.term || "--";
    const nextDrawTime = lastData?.nextDrawTime || Date.now();
    // 使用 useMemo 缓存 combinationResult，使其只在 lastData 变化时更新
    const combinationResult = useMemo(
        () =>{
            const result = lastData?.result;
            if (!Array.isArray(result) || result.length === 0){
                return '???';
            }
            const total = result.reduce((a, b) => a + b, 0);
            const combinationResult = `${result.join(" + ")} = ${total}`;
           return  combinationResult || "???"
        },
        [lastData]
    );
    const [timeLeft, setTimeLeft] = useState(nextDrawTime - Date.now());
    // 刷新刮刮卡
    const resetScratch = () => {
        scratchRef.current?.reset();
    };
    //根据combinationResult来重置刮层，只要有数据变化就关闭
    useEffect(() => {
        resetScratch();
    }, [combinationResult])

    // 监听 lastData 变化时，更新倒计时
    useEffect(() => {
        if (!nextDrawTime) return;
        const interval = setInterval(() => {
            const newTimeLeft = nextDrawTime - Date.now();
            setTimeLeft(newTimeLeft);
        }, 1000);

        return () => clearInterval(interval);
    }, [nextDrawTime]);

    const CleanData = () => {
        resetScratch();
        onClose();
    }
    const {hours, minutes, seconds} = convertMillisecondsToHMS(timeLeft);
    return (

        <Modal
            visible={isOpen}
            onClose={CleanData}
            footer={null}
            closable={false}
            unmountOnExit={false}
            wrapStyle={{
                // filter: isOpen ? 'blur(10px)' : 'none', // 根据状态控制模糊效果
                // backdropFilter: isOpen ? 'blur(10px)' : 'none', // 根据状态控制模糊效果
                backgroundColor: isOpen?'rgba(128, 128, 128, 0.95)':'transparent'
            }}
            style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                backgroundImage: `url(${scratcherBg})`,
                backgroundSize: "contain",
                backgroundRepeat: "repeat-x",
                paddingTop: "3vh",
                width: isMobile?"340px":'400px',
                touchAction: 'pan-y',
                overscrollBehaviorX: 'none'
            }}
        >
            <div style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                color:"white"}}>
                {/* 倒计时 */}
                <div style={{fontSize: "15px",}}>
                    <span>下一期: </span>
                    {timeLeft <= 0 ? (
                        <span style={{color: "white"}}>开奖中...</span>
                    ) : (
                        <>
                            {hours > 0 && (
                                <>
                                    <span>{twoDist(hours)}</span>
                                    <span> 小时 </span>
                                </>
                            )}
                            <span style={timerStyle}>{twoDist(minutes)}</span>
                            <span> 分 </span>
                            <span style={timerStyle}>{twoDist(seconds)}</span>
                            <span> 秒</span>
                        </>
                    )}
                </div>

                {/* 标题 */}
                <div style={{fontSize: "16px", marginTop: '10px', color: "white"}}>
                    <span>第 </span>
                    <span style={{color: "blue", fontSize: "18px", fontWeight: "bold",}}>{term}</span>
                    <span> 期开奖结果</span>
                </div>
                {/* 刮刮卡区域 */}
                <ScratchCard
                    ref={scratchRef}
                    height={120}
                    width={330}
                    brushSize={8}
                    className="custom-scratch">
                    <div
                        className="result-layer"
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "50px",
                            fontWeight: "bold",
                            color: "red",
                        }}
                    >
                        {combinationResult}
                    </div>
                </ScratchCard>
                <br/>
                {/* 按钮 */}
                <div style={{display: "flex", gap: "10px", justifyContent: "center"}}>
                    <Button
                        type="primary"
                        onClick={resetScratch}
                        style={{backgroundColor: "#ff0", color: "#000",fontWeight: "bold"}}>
                        刷新
                    </Button>
                    <Button
                        onClick={CleanData}
                        style={{backgroundColor: "#ddd",border:'none',color:'black',fontWeight: "bold"}}>
                        关闭
                    </Button>
                </div>
            </div>
        </Modal>
    );
}