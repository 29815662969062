import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {Avatar, Button, Table} from "@arco-design/web-react";
import {CountdownTimer} from "./CountdownTimer";
import {askFor, convertColor, extractTime, pc28ResultCalculate} from "../utils/Utils";
import {ScratchCardsDialog} from "./ScratchCardsDialog";
import {isMobile} from "react-device-detect";
// import {isMobile} from "react-device-detect";

export const PC28Component = ({type = 0}) => {
    const [lotteryData,setLotteryData] = useState([]);
    const [lastData,setLastData] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [lastTerm, setLastTerm] = useState(null); // 记录最后一期的期号
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 获取数据的方法
    const fetchData = useCallback(async () => {
        // setLoading(true);
        try {
            // const response = await axios.get(`http://af01833ea4ece3e51.awsglobalaccelerator.com/open/history?game=Canadian`);
            const response = await axios.get(`https://api.kkaakj.com/open/history?game=Canadian`);
            const { resultCode, data } = response.data;

            if (resultCode && data.length > 0) {
                const newData = data.reverse().map(item => ({
                    ...item,
                    detail: pc28ResultCalculate(item.result)
                }));
                const firstData = newData.shift();
                // 只有当最新期号发生变化时，才更新数据
                if (firstData.term !== lastTerm) {
                    setLastData(firstData)
                    setLotteryData(newData);
                    setLastTerm(firstData.term);
                    return true; // 数据已更新
                }
            }
        } catch (error) {
            console.error("数据加载失败:", error);
        } finally {
            // setLoading(false);
        }
        return false; // 数据未更新
    },[lastTerm]);


    //获取数据
    useEffect(() => {
        fetchData();
    },[fetchData])

    const pc28columns = [
        {title:'期号',dataIndex: 'term',key:'term',align: 'center'},
        {title:'开奖时间',dataIndex: 'drawDate',key:'drawDate',align: 'center',  render: (record) => extractTime(record)},
        {title:'结果', dataIndex: 'detail', key:'detail',align: 'center', render:(record)=> record.combinationResult},
    ]

    const pcNiuniuColumns = [
        { title: '期号', dataIndex: 'term', key: 'term', align: 'center' },
        {
            title: '号码一',
            dataIndex: 'detail',
            key: 'numberOne',
            align: 'center',
            render: (record) => <NumberItem number={record.oneNumber}/>
        },
        {
            title: '号码二',
            dataIndex: 'detail',
            key: 'numberTwo',
            align: 'center',
            render: (record) => <NumberItem number={record.twoNumber}/>
        },
        {
            title: '号码三',
            dataIndex: 'detail',
            key: 'numberThree',
            align: 'center',
            render: (record) => <NumberItem number={record.threeNumber}/>
        },
        // {
        //     title: '开奖时间',
        //     dataIndex: 'drawDate',
        //     align: 'center',
        //     key: 'drawDate',
        //     render: (record) => extractTime(record)
        // },
    ];
    return (
        < >


                <div className="timerAndLast">
                    {
                    lastData != null ?
                            <>
                                <CountdownTimer isTimestamp={true}
                                                initialTime={lastData.nextDrawTime || 0}
                                                onRefresh={fetchData} />
                                {/*<TimerComponent*/}
                                {/*    term={lastData.term || "--"}*/}
                                {/*    timestamp={lastData.nextDrawTime || 0}*/}
                                {/*    onRefresh={fetchData} // 倒计时结束后自动请求最新数据*/}
                                {/*/>*/}
                                <LastTermComponent lasResult={lastData} openDialog={()=>{
                                    setIsModalOpen(true);
                                }} />
                                <ScratchCardsDialog lastData={lastData}
                                                    isOpen={isModalOpen}
                                                    onClose={() => setIsModalOpen(false)} />
                            </>
                    :<span>数据加载中</span>
                    }
                </div>

            <Table data={lotteryData}
                   border={{
                       wrapper: true,
                       cell: true,
                       fixed: true,
                   }}
                   columns={type === 0? pc28columns:pcNiuniuColumns} rowKey={'term'}
                   pagination={false}
                   // loading={loading}
            />
        </>
    )
}

const NumberItem = ({number}) => {
  return   <div style={{display: "flex", justifyContent: "center",alignItems: "center"}}>
      <div style={{
          color: "white",
          width: 'auto',
          padding: "5px",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",  // 确保内容垂直居中
          justifyContent: "center", // 确保内容水平居中
          textAlign: "center", // 确保文字居中
           backgroundColor: convertColor(number) }}>
          <span>{askFor(number)}</span>
      </div>
  </div>

}


const LastTermComponent = ({lasResult,openDialog}) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: "24px",
            width: "100%", // 确保外层容器宽度足够
            padding: "20px", // 添加内边距，避免内容贴边
            boxSizing: "border-box" // 防止 padding 影响布局
        }}>
    <span>
        最新 <span style={{ color:'red', fontSize: '26px' }}>{lasResult.term}</span> 期
    </span>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginTop: "10px",
                // flexWrap: "wrap", // 允许内容换行
                justifyContent: "center", // 水平居中
                width: "100%", // 确保容器宽度足够
                maxWidth: "320px" // 限制最大宽度，避免内容过长
            }}>
                <div className='number'>{lasResult.detail.oneNumber}</div>
                <span>+</span>
                <div className='number'>{lasResult.detail.twoNumber}</div>
                <span>+</span>
                <div className='number'>{lasResult.detail.threeNumber}</div>
                <span>=</span>
                <div className='numberTotal'>{lasResult.detail.total}</div>
                <Avatar size={25} style={{ backgroundColor: lasResult.detail.isSize ? "red" : "#14a9f8" ,width:'80%',height:'35px'}}>
                    {lasResult.detail.result1}
                </Avatar>
                <Avatar size={25} style={{ backgroundColor: lasResult.detail.isEven ? "red" : "#14a9f8" ,width:'80%',height:'35px' }}>
                    {lasResult.detail.result2}
                </Avatar>
            </div>
            <Button
                style={{
                    color:'white',
                    backgroundColor:'red',
                    textAlign: 'center',
                    fontWeight:'bold',
                    fontSize: '0.7em',
                    marginTop: '20px',
                    width: isMobile?'50%':'30%',
                    border: 'none',
                    height:'40px',
                    borderRadius:'4px',
                }}
                onClick={openDialog}
            >
                咪牌模式
            </Button>
        </div>
    )
}