
export  function pc28ResultCalculate(result) {
    // 如果 result 不是数组或为空，则返回默认对象
    if (!Array.isArray(result) || result.length === 0) {
        return {
            oneNumber: undefined,
            twoNumber: undefined,
            threeNumber: undefined,
            total: 0,
            isEven: false,
            isSize: false,
            isLeopard: false,
            result1: '',
            result2: '',
            result3: '',
            combinationResult: ''
        };
    }
    // 获取数组中各个位置的数字，如果不存在则返回 undefined
    const oneNumber = result.length > 0 ? result[0] : undefined;
    const twoNumber = result.length > 1 ? result[1] : undefined;
    const threeNumber = result.length > 2 ? result[2] : undefined;

    // 计算总和值，注意初始值设为 0
    const total = result.reduce((a, b) => a + b, 0);

    // 判断总和值是否为偶数
    const isEven = total % 2 === 0;

    // 判断总和值是否大于 13
    const isSize = total > 13;

    // 判断是否为豹子：所有数字是否相同
    // 注意：Dart 代码中使用了 result.toSet().isEmpty，这里更正为判断 Set 的 size 是否为 1
    const uniqueNumbers = new Set(result);
    const isLeopard = uniqueNumbers.size === 1;

    // 根据计算结果返回中文描述
    const size = isSize ? "大" : "小";
    const oddAndEven = isEven ? "双" : "单";
    const leopard = isLeopard ? "豹子" : "";

    // 拼接组合结果字符串
    const combinationResult = `${result.join(" + ")} = ${total} ${size} ${oddAndEven} ${leopard}`;

    // 返回结果对象，类似 Dart 中的 PC28Detail 实例
    return {
        oneNumber,
        twoNumber,
        threeNumber,
        total,
        isEven,
        isSize,
        isLeopard,
        result1: size,
        result2: oddAndEven,
        result3: leopard,
        combinationResult,
    };
}

export  const convertMillisecondsToHMS = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000); // 转换成秒
    const day = Math.floor(totalSeconds / (3600 * 24)); // 计算天数
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600); // 计算剩余的小时数
    const minutes = Math.floor((totalSeconds % 3600) / 60); // 计算剩余的分钟数
    const seconds = totalSeconds % 60; // 计算剩余的秒数
    return {day, hours, minutes, seconds };
};

export const twoDist = (number) => {
    return number >=10? `${number}`:`0${number}`;
}

// 颜色转换函数
export const convertColor = (number) => {
    if (number === null || number === undefined) return "transparent";
    if (number === 0) return "red";
    if (number === 1) return "black";
    if (number >= 2 && number <= 5) return "lightseagreen";
    if (number >= 6 && number <= 9) return "dodgerblue";
    return "transparent";
};
const names = [
    '牛牛', '牛一', '牛二', '牛三', '牛四',
    '牛五', '牛六', '牛七', '牛八', '牛九'
];
export  function askFor(number) {
    if (number == null) {
        return '';
    }
    const ask = number % 10;
    return names[ask] || `牛${ask}`;
}

// 获取当天的开始时间和结束时间的时间戳
export  const getTodayTimestamps = () => {
    const now = new Date(); // 当前时间

    // 当天的开始时间（00:00:00）
    const startOfDay = new Date(now);
    startOfDay.setHours(0, 0, 0, 0); // 设置为 00:00:00.000

    // 当天的结束时间（23:59:59）
    const endOfDay = new Date(now);
    endOfDay.setHours(23, 59, 59, 999); // 设置为 23:59:59.999

    // 返回时间戳（以毫秒为单位）
    return {
        startTimestamp: startOfDay.getTime(),
        endTimestamp: endOfDay.getTime(),
    };
};

export function extractTime(datetime) {
    const match = datetime.match(/\d{2}:\d{2}:\d{2}/);
    return match ? match[0] : null;
}
