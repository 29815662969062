import React, {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";

import {Avatar, Table, Tabs} from "@arco-design/web-react";
import {CountdownTimer} from "./CountdownTimer";
import { isMobile } from 'react-device-detect';
import './css/Open.css'

const zodiacs = {
    1: '鼠',
    2: '牛',
    3: '虎',
    4: '兔',
    5: '龙',
    6: '蛇',
    7: '马',
    8: '羊',
    9: '猴',
    10: '鸡',
    11: '狗',
    12: '猪',
};

const zColors = {
    1: 'red', // 红
    2: 'green', // 绿
    3: 'dodgerblue', // 蓝
};


export const SixMarkComponent = () => {
    const currentYear = new Date().getFullYear();
    const yearList =useMemo(() => {
        return [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4];
    }, [currentYear]);
    // 记录最新期号，由 SixMarkLastComponent 更新
    const [lastIssue, setLastIssue] = useState(null);
    return (
        <>
            <SixMarkLastComponent setLastIssue={setLastIssue}/>
            <SixMarkHistoryComponent yearList={yearList} setLastIssue={lastIssue}/>
        </>
    );
}

export const SixMarkHistoryComponent = ({ yearList, lastIssue }) => {
    const [sixMarkData, setSixMarkData] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [selectIndex, setSelectIndex] = useState(0);

    // 获取数据的方法
    const historyData = async (year) => {
        // setLoading(true);
        try {
            const response = await axios.get(`https://1680660.com/smallSix/findSmallSixHistory.do?year=${year}&type=1`);
            const { errorCode, result } = response.data;
            if (errorCode !== 0) {
                console.error('数据加载失败:', errorCode);
                return;
            }

            const { bodyList } = result.data;
            if (!Array.isArray(bodyList)) {
                console.error('数据加载失败结果不是集合:', bodyList);
                return;
            }

            if (bodyList.length > 0) {
                const newData = bodyList.map((item) => ({
                    ...item,
                    drawCodes: item.preDrawCode.split(',').map((x) => parseInt(x)),
                }));
                setSixMarkData(newData);
                return true; // 数据已更新
            } else {
                setSixMarkData([]);
            }
        } catch (error) {
            console.error('数据加载失败:', error);
        } finally {
            // setLoading(false);
        }
        return false; // 数据未更新
    };

    // 监听 selectIndex 变化调用 historyData
    useEffect(() => {
        historyData(yearList[selectIndex]);
    }, [selectIndex, yearList]);

    // 当 lastIssue 变化时（也就是最新期号变化时），刷新数据
    useEffect(() => {
        if (lastIssue && selectIndex === 0) {
            historyData(yearList[selectIndex]);
        }
    }, [lastIssue, yearList, selectIndex]);

    const sixMarkColumns = [
        {
            title: isMobile?'期号':'日期/期号',
            dataIndex: 'issue',
            key: 'issue',
            align: 'center',
            render: (col, record, index) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span> {!isMobile ? record.preDrawDate:''} {record.issue}期</span>
                </div>
            ),
        },
        {
            title: '正码',
            dataIndex: 'drawCodes',
            key: 'drawCodes',
            align: 'center',
            render: (col, record, index) => (
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                    {record.drawCodes.map((item, index) => {
                        if (index === record.drawCodes.length - 1) {
                            return null; // 跳过最后一个元素
                        }
                        const color = zColors[record.color[index]];
                        const zodiac = zodiacs[record.czAndFe[index]];
                        return (
                            <SixMarkItem
                                key={index} // 添加 key
                                number={item}
                                color={color}
                                hint={zodiac}
                            />
                        );
                    })}
                </div>
            ),
        },
        {
            title: '特码',
            dataIndex: 'drawCodes',
            key: 'drawCodes.last',
            align: 'center',
            render: (col, record, index) => {
                const drawCodesLast = record.drawCodes[record.drawCodes.length - 1];
                const color = zColors[record.color[record.color.length - 1]];
                const zodiac = zodiacs[record.czAndFe[record.czAndFe.length - 1]];
                return (
                    <SixMarkItem
                        key={record.preDrawDate - record.issue}  // 添加 key
                        number={drawCodesLast}
                        color={color}
                        hint={zodiac}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Tabs
                activeTab={String(selectIndex)}
                size="large"
                type={'capsule' || 'rounded'}
                onChange={key => setSelectIndex(Number.parseInt(key))}
            >
                {yearList.map((item, index) => (
                    <Tabs.TabPane
                        key={String(index)} // 添加 key
                        title={item}
                    />
                ))}
            </Tabs>
            <Table
                border={{
                    wrapper: true,
                    cell: true,
                    fixed: true,
                }}
                columns={sixMarkColumns}
                data={sixMarkData}
                pagination={false}
                // loading={loading}
                rowKey={(record, index) => record.preDrawDate - record.issue} // 使用 index 作为 key
            />
        </>
    );
};



const SixMarkLastComponent = ({ setLastIssue }) => {
    const [sixMarkLastData, setSixMarkLastData] = useState(null);

    // 获取数据的方法，使用 async/await
    const lastFetchData = useCallback(async () => {
        try {
            const response = await axios.get(`https://1680660.com/smallSix/findSmallSixInfo.do`);
            const { errorCode, result } = response.data;
            if (errorCode !== 0) {
                console.error('数据加载失败:', errorCode);
                return false;
            }
            if (result.data != null) {
                const data = {
                    ...result.data,
                    drawCodes: result.data.preDrawCode.split(',').map(x => {
                        const num = parseInt(x, 10); // 显式指定进制
                        return isNaN(num) ? 0 : num; // 如果转换失败，返回默认值 0
                    }),
                };
                // 只有当最新期号发生变化时，才更新数据
                if (data.preDrawIssue !== setLastIssue.lastIssue) {
                    setSixMarkLastData(data);
                    setLastIssue(data.preDrawIssue);
                    return true; // 数据已更新
                }
            } else {
                setSixMarkLastData(null);
            }
        } catch (error) {
            console.error('数据加载失败:', error);
        }
        return false; // 数据未更新
    }, [setLastIssue]);

    // 组件挂载时请求数据
    useEffect(() => {
        lastFetchData();
    }, [lastFetchData]);

    if (sixMarkLastData === null) return null;

    // 检查 drawTime 是否有效
    const nextDrawTime = Date.parse(sixMarkLastData.drawTime);
    if (isNaN(nextDrawTime)) {
        console.error('Invalid drawTime:', sixMarkLastData.drawTime);
        return null; // 或者提供一个默认的时间戳
    }

    return (
        <div className='timerAndLast'>
            {/*<TimerComponent timestamp={nextDrawTime} refreshTime={10000} onRefresh={lastFetchData} />*/}
            <CountdownTimer isTimestamp={true} initialTime={nextDrawTime} refreshTime={10000} onRefresh={lastFetchData} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px',
                    marginBottom: '15px',
                    fontSize: '20px',
                }}
            >
        <span>
          最新{' '}
            <span style={{ color: 'red', fontSize: '26px' }}>{sixMarkLastData.preDrawIssue}</span> 期
        </span>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '10px' }}>
                    {sixMarkLastData.drawCodes.map((item, index) => {
                        const color = zColors[sixMarkLastData.color[index]] || 'black'; // 如果索引无效，使用默认颜色
                        const zodiac = zodiacs[sixMarkLastData.chineseZodiac[index]] || '未知'; // 如果索引无效，使用默认生肖
                        return (
                            <div key={index} style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
                                {index === sixMarkLastData.drawCodes.length - 1 && <span style={{ fontSize: '24px' }}> + </span>}
                                <SixMarkItem number={item} color={color} hint={zodiac} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const SixMarkItem = ({ number, hint, color }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',fontSize: isMobile?'18px':'20px' }}>
            <Avatar size={isMobile?28:33} style={{ backgroundColor: `${color}`}}>{number}</Avatar>
            <span>{hint}</span>
        </div>
    );
};

