import React, {useEffect, useState} from "react";
import {convertMillisecondsToHMS, twoDist} from "../utils/Utils";
import {isMobile} from 'react-device-detect';

// 公共倒计时组件
export  const CountdownTimer = ({
                            initialTime = 0,  // 初始倒计时（秒或时间戳）
                            isTimestamp = false, // 是否为时间戳模式
                            status = "开奖中...",
                            refreshTime = 3000,
                            term = "下一期",
                            onRefresh
                        }) =>{
    const [timeLeft, setTimeLeft] = useState(isTimestamp ? initialTime - Date.now() : initialTime * 1000);
    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        if (isTimestamp) {
            setTimeLeft(initialTime - Date.now());
        } else {
            setTimeLeft(initialTime * 1000);
        }
    }, [initialTime, isTimestamp]);

    useEffect(() => {
        if (timeLeft <= 0) return;

        const interval = setInterval(() => {
            setTimeLeft((prevTime) => Math.max(prevTime - 1000, 0)); // 确保不小于 0
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);

    useEffect(() => {
        if (timeLeft > 0 || isRefreshing || !onRefresh) return;

        const handleRefresh = async () => {
            setIsRefreshing(true);
            const refreshInterval = setInterval(async () => {
                const updated = await onRefresh();
                if (updated) {
                    clearInterval(refreshInterval);
                    setIsRefreshing(false);
                }
            }, refreshTime);

            return () => clearInterval(refreshInterval);
        };
        // 立即执行，不返回 Promise
        handleRefresh().catch(console.error);
    }, [timeLeft, onRefresh, isRefreshing, refreshTime]);

    const { days, hours, minutes, seconds } = convertMillisecondsToHMS(timeLeft);
    const showFullFormat = days > 0 || hours > 0;
    const PC28Item = () => {
        return <>
            <span>下一期:</span>
            { timeLeft <= 0 ? <span style={{ color: "red" }}>{status}</span>:
                <>
                    {days > 0 && <TimeItem number={days} hint={'天'}/> }
                    {hours > 0 && <TimeItem number={hours} hint={'小时'}/> }
                    <TimeItem number={minutes} hint={'分'}/>
                    <TimeItem number={seconds} hint={'秒'}/>
                </> }
        </>
    }

    const VideoBaccaratItem = () => {
        return <>
            <span>{term}:</span>
            {timeLeft > 0 ? <TimeItem number={timeLeft /1000} hint={'秒'}/> :<span style={{ color: "red" }}>{status}</span>}
        </>
    }
    return (
        <div style={{
            display:'flex',
            textAlign: "center",
            fontSize: showFullFormat?'15px':"20px",
            marginTop:"20px",
            gap:"5px",
            alignItems:'center',
            justifyContent:'center' }}>
            {isTimestamp ?<PC28Item />:<VideoBaccaratItem />}
        </div>
    );
}

const TimeItem = ({number,hint}) => {
    return <>
            <span style={{
                fontWeight: "bold",
                color: "white" ,
                fontSize:isMobile?'25px':'28px',
                backgroundColor:'red',
                borderRadius:'4px',
                width:isMobile?'12%':'6%'}}>{twoDist(number)}</span>
        <span> {hint} </span>
    </>
}
